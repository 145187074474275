<template>
  <div id="home-blog">
    <router-view />
  </div>
</template>

<script>
  export default { }
</script>

<style scoped>
</style>
